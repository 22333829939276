import { Tween } from 'react-gsap';
import PropTypes from 'prop-types';
import React from 'react';

const defaultTween = {
  duration: 1,
  ease: 'Power3.easeOut',
  paused: true
};

const defaultAnimation = {
  distance: 100,
  from: 'bottom'
};

const getEventType = event => {
  if (event.type === 'enter' && event.scrollDirection === 'FORWARD') {
    return 'play';
  }

  if (event.type === 'leave' && event.scrollDirection === 'REVERSE') {
    return 'reverse';
  }

  if (event.state === 'DURING' && event.scrollDirection === 'PAUSED') {
    return 'play';
  }

  return null;
};

export const Animated = ({ animation, children, event, stagger, tween }) => {
  const anim = Object.assign({}, defaultAnimation, animation);

  const { from, distance } = anim;

  const axis = from === 'left' || from === 'right' ? 'x' : 'y';
  const isNegative = from === 'left' || from === 'top';

  const fromTo = {
    [stagger ? 'staggerFrom' : 'from']: { [axis]: isNegative ? -distance : distance, opacity: 0 },
    [stagger ? 'staggerTo' : 'to']: { [axis]: 0, opacity: 1 }
  };

  const twn = Object.assign({}, defaultTween, fromTo, stagger ? { stagger } : {}, tween);

  return (
    <Tween {...twn} playState={getEventType(event)}>
      {children}
    </Tween>
  );
};

Animated.propTypes = {
  animation: PropTypes.shape({
    distance: PropTypes.number,
    from: PropTypes.string
  }),
  children: PropTypes.node,
  event: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stagger: PropTypes.number,
  tween: PropTypes.object
};
