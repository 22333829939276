/* eslint-disable id-length */
import * as $ from 'jquery';
import { Power3, TimelineMax, TweenMax } from 'gsap';
import Hammer from 'hammerjs';

export class Cards {
  constructor() {
    this.container = $('*[data-testimonial-cards]');
    this.init();
  }

  init() {
    // Setting containers
    this.active = 0;

    this.cards = this.container.find('*[data-testimonial-card]');

    this.prepareCards();

    this.prevArrow = this.container.find('*[data-testimonial-arrow="left"]');
    this.nextArrow = this.container.find('*[data-testimonial-arrow="right"]');

    this.prevArrow.on('click', () => this.swapCards(false));
    this.nextArrow.on('click', () => this.swapCards());

    const hammer = new Hammer(this.container.get(0));

    hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
    hammer.on('swipeup', () => this.swapCards());
    hammer.on('swipedown', () => this.swapCards(false));
  }

  prepareCards() {
    this.cards.each((i, card) => {
      $(card).attr('testimonial-index', i);
      new TimelineMax().set(card, {
        scale: (100 - i * 15) / 100,
        yPercent: i * 12,
        zIndex: this.cards.length - i
      });
    });
  }

  swapCards(next = true) {
    if (this.swapping) {
      return;
    }

    this.swapping = true;

    const cards = $('*[data-testimonial-card]');
    const cloned = $(cards[next ? 0 : cards.length - 1]);
    const clone = cloned.clone();

    if (next) {
      this.active = this.active < cards.length - 1 ? (this.active += 1) : (this.active = 0);
    } else {
      this.active = this.active > 0 ? (this.active -= 1) : cards.length - 1;
    }

    clone.css({ zIndex: next ? 0 : cards.length + 1 });

    $(cards[next ? cards.length - 1 : 0])[next ? 'after' : 'before'](clone);

    TweenMax.fromTo(
      clone,
      0.5,
      {
        alpha: 0,
        scale: next ? (100 - cards.length * 15) / 100 : 1.15,
        y: 0,
        yPercent: next ? cards.length * 12 : -12
      },
      {
        alpha: 1,
        ease: Power3.easeOut,
        scale: next ? (100 - (cards.length - 1) * 15) / 100 : 1,
        y: 0,
        yPercent: next ? (cards.length - 1) * 12 : 0
      }
    );

    if (next) {
      cards.each((i, card) => {
        TweenMax.to(card, 0.5, {
          ease: Power3.easeOut,
          opacity: i === 0 ? 0 : 1,
          scale: (100 - i * 15 + 15) / 100,
          yPercent: i * 12 - 12
        });

        $(card).css({ zIndex: this.cards.length - i });
      });
    } else {
      cards.each((i, card) => {
        TweenMax.to(card, 0.5, {
          ease: Power3.easeOut,
          opacity: i === cards.length - 1 ? 0 : 1,
          scale: (100 - (i + 1) * 15) / 100,
          yPercent: (i + 1) * 12
        });

        $(card).css({ zIndex: this.cards.length - i });
      });
    }

    setTimeout(() => {
      cloned.remove();
      this.swapping = false;
    }, 500);
  }
}
