import { IntroAnimation, IntroWrapper } from './Intro.style';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const animationData = require('../../../../../static/animations/intro.json');

export class Intro extends Component {
  static propTypes = {
    onDismiss: PropTypes.func
  };

  state = {
    complete: false,
    isStopped: true
  };

  dismiss = () => {
    document.body.classList.remove('loading');

    return this.setState({ complete: true, isStopped: true }, () => {
      if (this.props.onDismiss) {
        this.props.onDismiss();
      }
    });
  };

  componentDidMount() {
    document.body.classList.add('loading');

    setTimeout(() => {
      this.setState({ isStopped: false });
    }, 0);

    setTimeout(() => {
      this.dismiss();
    }, 2000);
  }

  render() {
    const defaultOptions = {
      animationData,
      autoplay: false,
      loop: false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <IntroWrapper isActive={!this.state.complete}>
        <IntroAnimation>
          <Lottie height="100%" isStopped={this.state.isStopped} options={defaultOptions} width="100%" />
        </IntroAnimation>
      </IntroWrapper>
    );
  }
}
