import { colors, fonts } from '../../variables';
import { ease, generateProps, mq, transitions, variations } from 'styled-gen';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

const sizeVariations = {
  default: css`
    height: 3rem;
    padding: 0 2rem;
    font-size: ${fonts.size('xs')};
    letter-spacing: 0.1rem;
  `,

  large: css`
    font-size: ${fonts.size('xs')};
    height: 3.75rem;
    letter-spacing: 0.15625rem;
    min-width: 12.5rem;
    padding: 0 2rem;

    ${mq.tabletLandscape(css`
      font-size: ${fonts.size('sm')};
      height: 5rem;
      min-width: 15.635rem;
    `)}
  `,

  small: css`
    height: 2.5rem;
    padding: 0 1.5rem;
    font-size: ${fonts.size('xs')};
    letter-spacing: 0.1rem;
  `
};

const colorVariations = {
  dark: css`
    background-color: ${colors.n08};
    box-shadow: 0 0.25rem 1rem ${rgba(colors.n08, 0.15)};
    color: ${colors.p06};

    ${mq.tabletLandscape(css`
      &:hover {
        color: ${colors.n06};
        background-color: ${colors.n02};
      }
    `)}
  `,

  default: css`
    background-color: ${colors.p06};
    box-shadow: 0 0.25rem 1rem ${rgba(colors.n08, 0.15)};
    color: ${colors.n01};

    ${mq.tabletLandscape(css`
      &:hover {
        color: ${colors.n06};
        background-color: ${colors.n02};
      }
    `)}
  `,

  lined: css`
    color: ${colors.p06};
    background-color: transparent;
    border: 0.125rem solid ${rgba(colors.p06, 0.25)};

    ${mq.tabletLandscape(css`
      &:hover {
        background-color: ${colors.p06};
        color: ${colors.n01};
      }
    `)}
  `,

  primary: css`
    background-color: ${colors.n08};
    box-shadow: 0 0.25rem 1rem ${rgba(colors.n08, 0.15)};
    color: ${colors.n01};

    ${mq.tabletLandscape(css`
      &:hover {
        color: ${colors.n08};
        background-color: ${colors.n03};
      }
    `)}
  `,

  white: css`
    color: ${colors.n06};
    background-color: ${colors.n01};

    ${mq.tabletLandscape(css`
      &:hover {
        color: ${colors.n01};
        background-color: ${colors.n06};
      }
    `)};
  `
};

const otherVariations = {
  fluid: css`
    width: 100%;
  `
};

export const Button = styled.a`
  ${transitions('all', '0.15s', ease.inOutCubic)};

  align-items: center;
  border-radius: 0.1875rem;
  display: inline-flex;
  font-weight: ${fonts.weights.bold};
  justify-content: center;
  text-transform: uppercase;
  white-space: nowrap;

  ${variations(sizeVariations)};
  ${variations(colorVariations)};
  ${variations(otherVariations)};

  ${generateProps}
`;
