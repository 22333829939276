import { Button, DocumentStyle } from '../../theme/components';
import { CookiesConsentButtonsWrapper, CookiesConsentContainer, CookiesConsentWrapper } from './CookiesConsent.style';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-react-tools';
import { Text } from '../../theme/components/Typography';
import { pageview } from '../../lib/gtag';
import { usePageContext } from '../PageProvider/PageProvider';
import React, { useEffect, useState } from 'react';

const consentLabel = 'cookie_consent';

const { GATSBY_GA } = process.env;

const isProduction = process.env.NODE_ENV === 'production';

export const CookiesConsent = () => {
  const { cookiesConsentText, strings } = usePageContext();
  const [hasConsent, setHasConsent] = useState(false);
  const [hasDismissed, setHasDismissed] = useState(true);

  const { accept, decline } = strings;

  useEffect(() => {
    const consentAnswer = window.localStorage.getItem(consentLabel);

    if (consentAnswer) {
      const withConsent = consentAnswer === 'true';

      setHasConsent(withConsent);

      if (withConsent) {
        pageview(window?.location?.pathname);
      }
    } else {
      setTimeout(() => {
        setHasDismissed(false);
      }, 500);
    }
  }, []);

  const handleAcceptClick = () => {
    setHasDismissed(true);
    setTimeout(() => {
      window.localStorage.setItem(consentLabel, 'true');
      setHasConsent(true);
    }, 500);
  };

  const handleDeclineClick = () => {
    setHasDismissed(true);
    setTimeout(() => {
      window.localStorage.setItem(consentLabel, 'false');
    }, 500);
  };

  return (
    <>
      {hasConsent ? (
        <>
          <Helmet>
            {isProduction && <script defer src="https://apps.elfsight.com/p/platform.js" />}
            {/* eslint-disable react/no-danger */}
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GATSBY_GA}`} />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${GATSBY_GA}', {
                  page_path: window.location.pathname,
                });
              `}
            </script>
          </Helmet>
        </>
      ) : (
        <CookiesConsentWrapper isActive={!hasDismissed}>
          <CookiesConsentContainer>
            <DocumentStyle>
              <Text as="div" pr={{ sm: 1.25, xs: 0 }} XSmall>
                <RichText content={cookiesConsentText} />
              </Text>
            </DocumentStyle>
            <CookiesConsentButtonsWrapper>
              <Button onClick={handleAcceptClick} small>
                {accept}
              </Button>
              <Button lined ml={{ sm: 1.25, xs: '4%' }} onClick={handleDeclineClick} small>
                {decline}
              </Button>
            </CookiesConsentButtonsWrapper>
          </CookiesConsentContainer>
        </CookiesConsentWrapper>
      )}
    </>
  );
};
