module.exports = {
  'de-de': {
    path: 'de'
  },
  'en-us': {
    default: true,
    path: 'en'
  },
  'es-es': {
    path: 'es'
  },
  'pt-pt': {
    path: 'pt'
  },
  'zh-cn': {
    path: 'cn'
  }
};
