import PropTypes from 'prop-types';
import React, { createContext } from 'react';

const PageContext = createContext({});

export const PageProvider = ({ children, ...other }) => (
  <PageContext.Provider value={other}>{children}</PageContext.Provider>
);

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
  locales: PropTypes.object,
  location: PropTypes.object,
  strings: PropTypes.object
};

export const usePageContext = () => {
  const context = React.useContext(PageContext);

  return context;
};

export const PageContextConsumer = PageContext.Consumer;
