import { colors } from '../../theme';
import { ease, mq, transitions } from 'styled-gen';
import { position, rgba } from 'polished';
import styled, { css } from 'styled-components';

export const CookiesConsentButtonsWrapper = styled.div`
  flex-shrink: 0;
  margin-top: 1rem;
  width: 100%;

  a {
    width: 48%;
  }

  ${mq.tablet(
    css`
      margin-top: 0;
      width: unset;

      a {
        width: unset;
      }
    `
  )}
`;

export const CookiesConsentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: 1rem;
  width: 100%;

  ${mq.tablet(
    css`
      padding: 2rem;
      flex-direction: row;
    `
  )}

  ${mq.tabletLandscape(
    css`
      padding: 1rem 2rem;
      max-width: 95vw;
      width: 71.35rem;
    `
  )}
`;

export const CookiesConsentWrapper = styled.div`
  ${position('fixed', null, 0, 0)};
  ${transitions('transform', 500, ease.inOutCubic)};

  align-items: center;
  background-color: ${colors.n01};
  box-shadow: 0 0 1.5rem ${rgba(colors.n08, 0.16)};
  display: flex;
  justify-content: center;
  transform: translateY(${({ isActive }) => (isActive ? 0 : '120%')});
  z-index: 999999;
`;
