import { AnimatedEl, AnimatedGroup } from '../';
import { ArticleCard } from './ArticleCard';
import { Grid } from '../../theme/components';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const ArticleList = props => {
  const [splittedArticles, setSplittedArticles] = useState({ left: [], right: [] });
  const { articles, inFrontpage } = props;

  useEffect(() => {
    const splitted = articles.reduce(
      (prev, article, index) => ({
        ...prev,
        [index % 2 === 0 ? 'left' : 'right']: [...(prev[index % 2 === 0 ? 'left' : 'right'] || []), article]
      }),
      {}
    );

    setSplittedArticles(splitted);
  }, [articles]);

  const { left, right } = splittedArticles;

  if (isEmpty(articles)) {
    return null;
  }

  return (
    <Grid>
      <AnimatedGroup>
        <AnimatedEl col md={inFrontpage ? 5 : 6} mdOffset={inFrontpage ? 1 : 0} sm={6} xs={false}>
          {left.map((article, index) => (
            <ArticleCard {...article} inFrontpage={inFrontpage} key={index} />
          ))}
        </AnimatedEl>
        <AnimatedEl col md={inFrontpage ? 5 : 6} sm={6} xs={false}>
          {right && right.map((article, index) => <ArticleCard {...article} inFrontpage={inFrontpage} key={index} />)}
        </AnimatedEl>
        <AnimatedEl col sm={false} xs={12}>
          {articles.map((article, index) => (
            <ArticleCard {...article} inFrontpage={inFrontpage} key={index} />
          ))}
        </AnimatedEl>
      </AnimatedGroup>
    </Grid>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  inFrontpage: PropTypes.bool
};
