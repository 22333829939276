import { Testimonials as TestimonialsComponent } from '../../components';
import PropTypes from 'prop-types';
import React from 'react';

export const Testimonials = props => {
  return <TestimonialsComponent {...props} withNoNegativeMargin />;
};

Testimonials.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      person: PropTypes.string,
      position: PropTypes.string,
      text: PropTypes.any
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
