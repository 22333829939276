import { Animated } from './Animated';
import { Controller, Scene } from 'react-scrollmagic';
import { Div, Row } from '../../theme/components';
import PropTypes from 'prop-types';
import React from 'react';

export const AnimatedGroup = ({ animation, children, parent, row, scene, stagger, tween, ...props }) => {
  const Wrapper = row ? Row : Div;
  const Parent = parent || Wrapper;

  return (
    <Controller>
      <Scene {...scene} triggerHook={(scene && scene.triggerHook) || 0.85}>
        {(progress, event) => {
          const stripedProps = { animation, event, tween };

          return (
            <Parent {...props}>
              {stagger ? (
                <Animated stagger={stagger} {...stripedProps}>
                  {React.Children.map(children, child =>
                    React.cloneElement(child, {
                      'data-animated': 'animated-item'
                    })
                  )}
                </Animated>
              ) : (
                React.Children.map(children, child =>
                  React.cloneElement(child, {
                    'data-animated': 'animated-item',
                    event
                  })
                )
              )}
            </Parent>
          );
        }}
      </Scene>
    </Controller>
  );
};

AnimatedGroup.propTypes = {
  animation: PropTypes.object,
  children: PropTypes.node,
  parent: PropTypes.any,
  row: PropTypes.bool,
  scene: PropTypes.object,
  stagger: PropTypes.number,
  tween: PropTypes.object
};

AnimatedGroup.defaultProps = {
  row: true
};
