import { Text } from '../../theme/components/Typography';
import { colors, fonts } from '../../theme';
import { mq } from 'styled-gen';
import { rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const TestimonialsArrow = styled.button`
  background-color: transparent;
  border: 0;
  display: none;
  outline: 0;

  ${mq.tablet(css`
    ${size('2rem')};

    display: block;
    padding: 0;
    position: absolute;
    color: ${colors.n04};
    z-index: 100;
  `)}

  ${mq.tabletLandscape(css`
    &:hover {
      cursor: pointer;
      fill: ${colors.n07};
    }
  `)}

  ${({ side }) =>
    side === 'left'
      ? css`
          left: 1.5rem;
        `
      : css`
          right: 1.5rem;
        `}

  svg {
    ${size('100%')};
  }
`;

const TestimonialsCard = styled.div`
  ${size('100%')};

  background-color: ${colors.n01};
  border-radius: 0.375rem;
  box-shadow: 0 1.5rem 2.5rem ${rgba(colors.n08, 0.16)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  position: absolute;
  top: 0;
  user-select: none;

  ${mq.tablet(css`
    padding: 0 7.25rem;
  `)}
`;

const TestimonialsCardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TestimonialsName = styled(Text).attrs({
  as: 'div',
  large: true
})`
  color: ${colors.n07};
  font-weight: ${fonts.weights.semibold};
  margin-top: 3rem;
  text-align: center;

  ${mq.phone(css`
    margin-top: 1.5rem;
    font-size: ${fonts.size('sm')};
  `)}
`;

const TestimonialsPosition = styled(Text).attrs({
  medium: true
})`
  margin-top: 0.25rem;
  text-align: center;

  ${mq.phone(css`
    margin-top: 0;
    font-size: ${fonts.size('xs')};
  `)}
`;

const TestimonialsText = styled(Text).attrs({
  as: 'div',
  large: true
})`
  color: ${colors.n07};
  text-align: center;

  ${mq.phone(css`
    font-size: ${fonts.size('sm')};
  `)}
`;

const TestimonialsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 25rem;
  justify-content: center;
  margin: auto;
  position: relative;
  ${({ withNoNegativeMargin }) =>
    !withNoNegativeMargin &&
    css`
      margin-bottom: -${25 / 2}rem;
    `}

  ${mq.tabletLandscape(css`
    ${({ withNoNegativeMargin }) =>
      !withNoNegativeMargin &&
      css`
        margin-bottom: -${53 / 2}rem;
      `}
    width: 53rem;
  `)};
`;

export {
  TestimonialsArrow,
  TestimonialsCard,
  TestimonialsCardContent,
  TestimonialsName,
  TestimonialsPosition,
  TestimonialsText,
  TestimonialsWrapper
};
