import { colors, fonts } from '../../../../theme';
import { ease, mq, transitions } from 'styled-gen';
import { position, rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const TopbarContent = styled.div`
  ${transitions('all', '0.75s', ease.outQuint)};
  ${size('100%')};

  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  max-width: 76rem;
  padding: 0 2rem;
  margin: auto;
  z-index: 1;

  ${mq.tabletLandscape(css`
    height: 6rem;
  `)}

  ${({ isScrolling }) =>
    isScrolling &&
    css`
      background-color: ${rgba(colors.n01, 0.95)};

      ${mq.tabletLandscape(css`
        height: 4rem;
        background-color: transparent;
      `)}
    `}

  .topbar-button {
    &:hover {
      & ~ .hst-submenu {
        opacity: 1;
        transform: translateY(100%);
        visibility: visible;
      }
    }
  }
`;

const TopbarIconButton = styled.a`
  ${size('1.25rem')};

  line-height: 0;
  font-size: 0;
  color: ${({ white }) => (white ? colors.n01 : colors.n06)};

  svg {
    ${size('100%')};
  }

  ${mq.tabletLandscape(css`
    display: none;
  `)};
`;

const TopbarLanguageButton = styled.a`
  letter-spacing: 1px;
  font-size: ${fonts.size('xs')};
  font-weight: ${fonts.weights.bold};
  text-transform: uppercase;
  color: ${colors.n06};

  ${({ alternative }) =>
    alternative &&
    css`
      color: ${colors.n01};
    `}

  &:hover {
    & ~ .hst-language-list {
      opacity: 1;
      transform: translateY(100%);
      visibility: visible;
    }
  }
`;

const TopbarLanguageList = styled.ul`
  padding-left: 1.5rem;
  margin-top: 3rem;
  display: flex;

  ${mq.tabletLandscape(css`
    ${transitions('all', '0.5s', ease.inOutQuint)};

    align-self: flex-end;
    background-color: ${colors.n01};
    border-radius: 0.25rem;
    box-shadow: 0 0 20px ${rgba(colors.n07, 0.15)};
    flex-direction: column;
    margin-left: 0;
    margin-top: -0.5rem;
    opacity: 0;
    overflow: hidden;
    padding-left: 0;
    position: absolute;
    transform: translateY(125%);
    visibility: hidden;
    z-index: 10;

    &:hover {
      opacity: 1;
      transform: translateY(100%);
      visibility: visible;
    }
  `)};
`;

const TopbarLanguageListItem = styled.li``;

const TopbarLanguageListItemLink = styled.a`
  color: ${colors.n06};
  display: block;
  font-size: ${fonts.size('sm')};
  font-weight: ${fonts.weights.bold};
  letter-spacing: 1px;
  padding: 0.5rem;
  text-transform: uppercase;

  ${mq.tabletLandscape(css`
    font-size: ${fonts.size('xs')};
    padding: 0.625rem 1.25rem;

    ${({ isActive }) =>
      !isActive &&
      css`
        &:hover {
          background-color: ${colors.n02};
        }
      `}
  `)};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${rgba(colors.n06, 0.5)};
      cursor: default !important;
    `}
`;

const TopbarLanguageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: 2rem;
`;

const TopbarMenu = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: auto;
`;

const TopbarMenuItem = styled.li`
  align-items: flex-start;
  display: ${({ visibility }) => (visibility === 'hidden' ? 'none' : 'flex')};
  flex-direction: column;
  height: auto;
  position: relative;

  & + & {
    margin-top: 1.5rem;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      & > a {
        font-weight: ${fonts.weights.extrabold};
      }
    `};

  ${mq.tabletLandscape(css`
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 100%;

    & + & {
      margin-left: 2rem;
      margin-top: 0;
    }

    ${({ isActive, isScrolling }) =>
      isActive &&
      css`
        & > a {
          font-weight: ${fonts.weights.semibold};
        }

        &::after {
          ${position('absolute', null, 0, -1, 0)};

          content: '';
          height: 0.1875rem;
          background-color: ${isScrolling ? colors.n06 : colors.n01};
        }
      `};
  `)};
`;

const TopbarMenuDesktopContainer = styled.div`
  display: none;

  ${mq.tabletLandscape(css`
    display: initial;
    height: 100%;
  `)};
`;

const TopbarMenuItemLabel = styled.a`
  color: ${colors.n06};
  font-size: ${fonts.size('sm')};
  font-weight: ${fonts.weights.semibold};
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 0 2rem;

  svg {
    display: none;
  }

  ${mq.tabletLandscape(css`
    color: ${({ isScrolling }) => (isScrolling ? colors.n06 : colors.n01)};
    letter-spacing: 0;
    padding: 0;

    svg {
      display: initial;
    }
  `)};

  ${mq.tabletLandscape(css`
    &:hover {
      opacity: 0.5;

      & ~ .hst-submenu {
        opacity: 1;
        transform: translateY(100%);
        visibility: visible;
      }
    }
  `)};
`;

const TopbarMenuItemSubmenu = styled.ul`
  padding-left: 3rem;

  ${mq.tabletLandscape(css`
    ${transitions('all', '0.5s', ease.inOutQuint)};

    align-self: flex-end;
    background-color: ${colors.n01};
    border-radius: 0.25rem;
    box-shadow: 0 0 20px ${rgba(colors.n07, 0.15)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -0.5rem;
    opacity: 0;
    padding-left: 0;
    position: absolute;
    visibility: hidden;
    transform: translateY(125%);
    z-index: 10;

    &:hover {
      opacity: 1;
      transform: translateY(100%);
      visibility: visible;
    }
  `)};
`;

const TopbarMenuItemSubmenuItem = styled.li`
  margin-top: 1.25rem;

  ${mq.tabletLandscape(css`
    margin-top: 0;
    overflow: hidden;

    & + & {
      border-top: 1px solid ${colors.n03};
    }

    &:first-of-type {
      border-top-left-radius: 0.1875rem;
      border-top-right-radius: 0.1875rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem;
    }
  `)};
`;

const TopbarMenuItemSubmenuItemLink = styled.a`
  color: ${colors.n05};
  display: block;
  font-size: ${fonts.size('sm')};
  font-weight: ${fonts.weights.semibold};

  ${mq.tabletLandscape(css`
    background-color: ${colors.n01};
    color: ${colors.n07};
    display: flex;
    font-size: ${fonts.size('base')};
    flex-direction: column;
    font-weight: ${fonts.weights.semibold};
    padding: 1.25rem 2rem 1.25rem;
    position: relative;
    white-space: nowrap;
    z-index: 10;

    ${({ disabled }) =>
      disabled
        ? css`
            cursor: default !important;
          `
        : css`
            &:hover {
              background-color: ${colors.n02};
            }
          `}
  `)};
`;

const TopbarMenuItemSubmenuItemLinkDisc = styled.div`
  ${size('0.75rem')};

  background-color: ${colors.p06};
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 0.35rem;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.n02};
    `}
`;

const TopbarMenuItemSubmenuItemLinkHeading = styled.div`
  color: ${rgba(colors.n06, 0.5)};
  font-size: ${fonts.size('xxs')};
  font-weight: ${fonts.weights.bold};
  text-transform: uppercase;
`;

const TopbarMenuItemSubmenuItemLinkLabel = styled.div`
  span {
    display: block;
  }

  .hst-submenu-note {
    color: ${colors.n05};
    font-size: ${fonts.size('sm')};
    font-weight: ${fonts.weights.regular};
  }
`;

const TopbarMenuItemSubmenuItemLinkText = styled.div`
  color: ${colors.n06};
  font-size: ${fonts.size('xs')};
  font-weight: ${fonts.weights.semibold};
  text-decoration: underline;
`;

const TopbarMobileMenuWrapper = styled.div`
  ${position('absolute', 0, 0, null, 0)}
  ${transitions('all', '0.75s', ease.outQuint)};

  background-color: ${colors.n01};
  width: 100vw;
  transform: translateY(-100%);
  z-index: 0;

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: 0 0 1rem ${rgba(colors.n08, 0.16)};
      transform: translateY(0);
    `}

  ${mq.tabletLandscape(css`
    display: none;
  `)}
`;

const TopbarMobileMenu = styled.div`
  ${size('100%')}

  overflow: hidden;
  padding-top: 4rem;

  .topbar-button {
    border: 0;
    box-shadow: none;
    color: ${rgba(colors.n04, 0.35)};
    height: auto;
    margin-top: 1rem;
    outline: 0;
    padding: 0;
    padding-left: 2rem;
  }
`;

const TopbarWrapper = styled.div`
  ${transitions('all', '0.75s', ease.inOutQuint)};

  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid ${rgba(colors.n01, 0.3)};
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  ${({ isScrolling }) =>
    isScrolling &&
    css`
      box-shadow: 0 0 1rem ${rgba(colors.n08, 0.16)};

      ${mq.tabletLandscape(css`
        background-color: ${rgba(colors.n01, 0.95)};
      `)}
    `}

  svg {
    ${transitions('all', '0.75s', ease.inOutQuint)};
  }

  ${mq.tabletLandscape(css`
    ${transitions('all', '0.75s', ease.outQuint)};

    svg {
      ${transitions('all', '0.75s', ease.outQuint)};
    }
  `)}
`;

export {
  TopbarContent,
  TopbarIconButton,
  TopbarLanguageButton,
  TopbarLanguageList,
  TopbarLanguageListItem,
  TopbarLanguageListItemLink,
  TopbarLanguageWrapper,
  TopbarMenu,
  TopbarMenuDesktopContainer,
  TopbarMenuItem,
  TopbarMenuItemLabel,
  TopbarMenuItemSubmenu,
  TopbarMenuItemSubmenuItem,
  TopbarMenuItemSubmenuItemLink,
  TopbarMenuItemSubmenuItemLinkDisc,
  TopbarMenuItemSubmenuItemLinkHeading,
  TopbarMenuItemSubmenuItemLinkLabel,
  TopbarMenuItemSubmenuItemLinkText,
  TopbarMobileMenuWrapper,
  TopbarMobileMenu,
  TopbarWrapper
};
