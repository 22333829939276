import { Layout, PageProvider } from '../components';
import { extractFromCamelCase } from 'prismic-react-tools';
import { get } from 'lodash';
import { getSeoFromDocument } from '../lib/getSeoFromDocument';
import NotFound from '../pages/404';
import PropTypes from 'prop-types';
import React from 'react';
import localesFromConfig from '../../langs-config';

const url = process.env.GATSBY_URL;
const isProduction = !!process.env.GATSBY_IS_PRODUCTION;

export default (Page, layoutData) => {
  const withPrismicPage = props => {
    const { location, pageContext, uri } = props;
    const common = get(pageContext, 'common');
    const document = get(pageContext, 'document');

    if (!common || (!document.isLive && isProduction)) {
      return <NotFound />;
    }

    const footer = extractFromCamelCase(common, 'footer');
    const cookiesConsent = extractFromCamelCase(common, 'cookiesConsent');
    const cookiesConsentText = cookiesConsent?.text;
    const lang = get(pageContext, 'lang');
    const langList = get(pageContext, 'langList');
    const contact = extractFromCamelCase(common, 'contact');
    const locale = localesFromConfig[lang];
    const noticeModal = extractFromCamelCase(common, 'noticeModal');
    const strings = extractFromCamelCase(common, 'strings');
    const testimonials = extractFromCamelCase(common, 'testimonials');

    const layout = {
      footer: { ...footer, contact, locale },
      locale,
      location,
      noticeModal,
      seo: {
        defaultMeta: {
          ...extractFromCamelCase(common, 'seo'),
          url: `${url}/${uri}`
        },
        meta: {
          ...getSeoFromDocument(document),
          url: `${url}/${uri}`
        }
      },
      testimonials,
      topbar: { ...extractFromCamelCase(common, 'topbar'), contact, langList, locale, location, uri },

      ...layoutData
    };

    const locales = Object.keys(localesFromConfig).reduce(
      (acc, locale) => ({ ...acc, [localesFromConfig[locale].path]: localesFromConfig[locale] }),
      {}
    );

    return (
      <PageProvider
        cookiesConsentText={cookiesConsentText}
        locale={locale.path}
        locales={locales}
        location={location}
        strings={strings}
      >
        <Layout {...layout}>
          <Page {...props} />
        </Layout>
      </PageProvider>
    );
  };

  withPrismicPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    uri: PropTypes.string
  };

  return withPrismicPage;
};
