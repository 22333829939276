import { colors } from '../..';
import { generateProps, mq, variations } from 'styled-gen';
import React from 'react';
import styled, { css } from 'styled-components';

const sizeVariations = {
  default: css`
    height: 2rem;
  `,

  large: css`
    height: 2rem;

    ${mq.tabletLandscape(css`
      height: 2.375rem;
    `)}
  `
};

const colorVariations = {
  default: css`
    color: ${colors.p06};
  `,
  white: css`
    color: ${colors.n01};
  `
};

const Svg = styled.svg`
  display: inline-block;
  fill: currentColor;
  width: auto;

  ${variations(sizeVariations)};
  ${variations(colorVariations)};
  ${generateProps};
`;

export const HydrustentLogo = props => (
  <Svg viewBox="0 0 1815 500" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M121.8 286.5H43.2v90.3H1.1V161.1h42.1v87.8h78.6v-87.8h42.1v215.7h-42.1z" />
    <path d="M252.8 296.4l-70.6-135.3h50l39.3 80.8 4.3 22.8h1.6l4.6-23.4 38.1-80.2h45.1l-70.3 135v80.2h-42.1z" />
    <path d="M383.2 160.9c4.3-.6 9.2-1.1 14.6-1.5 5.4-.4 11-.7 16.8-.9 5.7-.2 11.3-.3 16.7-.5 5.4-.1 10.3-.1 14.6-.1 19.1 0 35.4 2.7 48.9 8s24.5 12.8 32.9 22.4c8.4 9.6 14.5 21.1 18.4 34.4 3.9 13.3 5.8 28 5.8 44 0 14.6-1.8 28.6-5.5 42.1s-9.8 25.5-18.4 35.9c-8.6 10.5-19.9 18.9-34 25.2-14 6.4-31.4 9.5-52.1 9.5-3.3 0-7.6-.1-13.1-.3-5.4-.2-11.1-.5-16.9-.8-5.8-.3-11.4-.6-16.7-.9-5.4-.3-9.3-.7-12-1.1V160.9zm66.2 34.5c-4.5 0-9.1.1-13.7.3-4.6.2-8 .5-10.3.9v144.2c.8.2 2.2.3 4.2.4 1.9.1 4 .2 6.3.3 2.3.1 4.4.2 6.5.3 2 .1 3.6.1 4.6.1 11.9 0 21.8-2.1 29.7-6.3 7.9-4.2 14.1-9.8 18.7-16.8s7.9-15 9.7-24.1c1.9-9.1 2.8-18.5 2.8-28.1 0-8.4-.8-16.9-2.5-25.4s-4.7-16.1-9.1-22.9c-4.4-6.8-10.4-12.3-18-16.6-7.6-4.2-17.2-6.3-28.9-6.3z" />
    <path d="M582.4 163.1c4.7-.8 9.8-1.6 15.2-2.3 5.4-.7 10.9-1.3 16.3-1.7 5.4-.4 10.7-.7 15.8-.9 5.1-.2 9.8-.3 14.1-.3 10 0 19.9.9 29.7 2.8 9.7 1.9 18.4 5 26.1 9.5 7.7 4.5 13.8 10.7 18.4 18.6 4.6 7.9 6.9 17.8 6.9 29.7 0 17.6-4.1 32-12.3 43.1-8.2 11.1-19.2 18.8-32.9 23.1l15.1 9.5 49.2 82.1h-48.6L646.6 292l-22.1-4v88.3h-42.1V163.1zm64.6 32.3c-4.3 0-8.6.1-12.8.3-4.2.2-7.4.6-9.7 1.2v62.4h17.8c11.7 0 21-2.7 28-8s10.4-14.1 10.4-26.5c0-9.2-2.9-16.4-8.6-21.7-5.7-5.1-14.1-7.7-25.1-7.7z" />
    <path d="M884.6 161.1h40v139.1c0 14-1.9 26-5.5 36-3.7 10-8.8 18.4-15.4 24.9-6.6 6.6-14.6 11.4-24 14.5-9.4 3.1-19.8 4.6-31.1 4.6-54.6 0-81.9-24.9-81.9-74.8V161.1h42.2v136c0 8.4.8 15.5 2.3 21.4 1.5 5.9 4 10.6 7.2 14.2 3.3 3.6 7.4 6.1 12.3 7.7 4.9 1.5 10.7 2.3 17.2 2.3 12.9 0 22.3-3.6 28-10.8 5.7-7.2 8.6-18.8 8.6-34.8v-136z" />
    <path d="M1065.2 493.6c-10.3 4.3-21.1 6.4-31.9 6.4-10.8 0-21.6-2.2-31.9-6.4-20.5-8.5-36.6-24.5-45.1-45.1-17.5-42.5 2.7-91.4 45.1-109.1l16.5 39.9c-9.9 4.1-17.6 11.8-21.7 21.8-4.1 9.9-4.1 20.9 0 30.8 4.1 9.9 11.8 17.7 21.7 21.8 9.9 4.1 20.8 4.1 30.7 0 9.9-4.1 17.6-11.9 21.8-21.8 4.1-9.9 4.1-20.9 0-30.8l-.9-2.8-117.1-284.1c-8-20.3-7.8-42.5.5-62.7 8.6-20.6 24.6-36.6 45.1-45.1 42.4-17.6 91.2 2.6 108.8 45.1 8.6 20.6 8.6 43.3.1 63.9-8.6 20.6-24.6 36.6-45.1 45.2l-16.5-39.9c9.9-4.1 17.6-11.8 21.7-21.8 4.1-9.9 4.1-20.9 0-30.8-8.5-20.5-32-30.3-52.5-21.8-9.9 4.1-17.6 11.9-21.7 21.8-4.1 9.9-4.1 20.9 0 30.8l118 286.9c8.1 20.2 7.9 42.5-.5 62.7-8.6 20.5-24.6 36.5-45.1 45.1z" />
    <path d="M1275.8 198.7H1213v178.2h-42.3V198.7h-63.1v-37.6h168.2z" />
    <path d="M1297.7 161.1h128v37.6H1340V249h78v37.6h-78v52.7h87.2v37.6h-129.5z" />
    <path d="M1511.8 263.8l-16.9-33.9h-1.6l5 33.9V376h-40.1V159.1h30.8l81.7 114.8 16.4 32.9h1.5l-4.9-32.9V160.7h40.1v216.9h-30.9z" />
    <path d="M1813.9 198.8H1751v178.1h-42.3V198.8l-63.2-.1v-37.6l168.4.1z" />
  </Svg>
);
