import { colors } from '../../../../theme';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  fill: ${colors.n03};
  height: auto;
  margin-top: -30%;
  position: relative;
  transform: translate(-2.5vw, 32%);
  width: 230vw;
  z-index: -1;
`;

export const LogoCut = () => {
  return (
    <SVG
      version="1.1"
      viewBox="0 0 1815 500"
      x="0px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      y="0px"
    >
      <polygon points="121.8,286.5 43.2,286.5 43.2,376.8 1.1,376.8 1.1,161.1 43.2,161.1 43.2,248.9 121.8,248.9 121.8,161.1 163.9,161.1 163.9,376.8 121.8,376.8 " />
      <polygon points="252.8,296.4 182.2,161.1 232.2,161.1 271.5,241.9 275.8,264.7 277.4,264.7 282,241.3 320.1,161.1 365.2,161.1 294.9,296.1 294.9,376.3 252.8,376.3 " />
      <path d="M383.2,160.9c4.3-0.6,9.2-1.1,14.6-1.5c5.4-0.4,11-0.7,16.8-0.9c5.7-0.2,11.3-0.3,16.7-0.5 c5.4-0.1,10.3-0.1,14.6-0.1c19.1,0,35.4,2.7,48.9,8c13.5,5.3,24.5,12.8,32.9,22.4c8.4,9.6,14.5,21.1,18.4,34.4 c3.9,13.3,5.8,28,5.8,44c0,14.6-1.8,28.6-5.5,42.1c-3.7,13.5-9.8,25.5-18.4,35.9c-8.6,10.5-19.9,18.9-34,25.2c-14,6.4-31.4,9.5-52.1,9.5c-3.3,0-7.6-0.1-13.1-0.3c-5.4-0.2-11.1-0.5-16.9-0.8c-5.8-0.3-11.4-0.6-16.7-0.9 c-5.4-0.3-9.3-0.7-12-1.1V160.9z M449.4,195.4c-4.5,0-9.1,0.1-13.7,0.3c-4.6,0.2-8,0.5-10.3,0.9v144.2c0.8,0.2,2.2,0.3,4.2,0.4 c1.9,0.1,4,0.2,6.3,0.3c2.3,0.1,4.4,0.2,6.5,0.3c2,0.1,3.6,0.1,4.6,0.1c11.9,0,21.8-2.1,29.7-6.3c7.9-4.2,14.1-9.8,18.7-16.8 c4.6-7,7.9-15,9.7-24.1c1.9-9.1,2.8-18.5,2.8-28.1c0-8.4-0.8-16.9-2.5-25.4c-1.7-8.5-4.7-16.1-9.1-22.9c-4.4-6.8-10.4-12.3-18-16.6 C470.7,197.5,461.1,195.4,449.4,195.4z" />
      <path d="M582.4,163.1c4.7-0.8,9.8-1.6,15.2-2.3c5.4-0.7,10.9-1.3,16.3-1.7c5.4-0.4,10.7-0.7,15.8-0.9 c5.1-0.2,9.8-0.3,14.1-0.3c10,0,19.9,0.9,29.7,2.8c9.7,1.9,18.4,5,26.1,9.5c7.7,4.5,13.8,10.7,18.4,18.6c4.6,7.9,6.9,17.8,6.9,29.7 c0,17.6-4.1,32-12.3,43.1c-8.2,11.1-19.2,18.8-32.9,23.1l15.1,9.5l49.2,82.1h-48.6L646.6,292l-22.1-4v88.3h-42.1V163.1z M647,195.4 c-4.3,0-8.6,0.1-12.8,0.3c-4.2,0.2-7.4,0.6-9.7,1.2v62.4h17.8c11.7,0,21-2.7,28-8c7-5.3,10.4-14.1,10.4-26.5 c0-9.2-2.9-16.4-8.6-21.7C666.4,198,658,195.4,647,195.4z" />
      <path d="M884.6,161.1h40v139.1c0,14-1.9,26-5.5,36c-3.7,10-8.8,18.4-15.4,24.9c-6.6,6.6-14.6,11.4-24,14.5 c-9.4,3.1-19.8,4.6-31.1,4.6c-54.6,0-81.9-24.9-81.9-74.8V161.1h42.2v136c0,8.4,0.8,15.5,2.3,21.4c1.5,5.9,4,10.6,7.2,14.2 c3.3,3.6,7.4,6.1,12.3,7.7c4.9,1.5,10.7,2.3,17.2,2.3c12.9,0,22.3-3.6,28-10.8c5.7-7.2,8.6-18.8,8.6-34.8V161.1z" />
      <path d="M1065.2,493.6c-10.3,4.3-21.1,6.4-31.9,6.4c-10.8,0-21.6-2.2-31.9-6.4c-20.5-8.5-36.6-24.5-45.1-45.1 c-17.5-42.5,2.7-91.4,45.1-109.1l16.5,39.9c-9.9,4.1-17.6,11.8-21.7,21.8c-4.1,9.9-4.1,20.9,0,30.8c4.1,9.9,11.8,17.7,21.7,21.8 c9.9,4.1,20.8,4.1,30.7,0c9.9-4.1,17.6-11.9,21.8-21.8c4.1-9.9,4.1-20.9,0-30.8l-0.9-2.8L952.4,114.2c-8-20.3-7.8-42.5,0.5-62.7 c8.6-20.6,24.6-36.6,45.1-45.1c42.4-17.6,91.2,2.6,108.8,45.1c8.6,20.6,8.6,43.3,0.1,63.9c-8.6,20.6-24.6,36.6-45.1,45.2l-16.5-39.9 c9.9-4.1,17.6-11.8,21.7-21.8c4.1-9.9,4.1-20.9,0-30.8c-8.5-20.5-32-30.3-52.5-21.8c-9.9,4.1-17.6,11.9-21.7,21.8 c-4.1,9.9-4.1,20.9,0,30.8l118,286.9c8.1,20.2,7.9,42.5-0.5,62.7C1101.7,469,1085.7,485,1065.2,493.6z" />
      <polygon points="1275.8,198.7 1213,198.7 1213,376.9 1170.7,376.9 1170.7,198.7 1107.6,198.7 1107.6,161.1 1275.8,161.1 " />
      <polygon points="1297.7,161.1 1425.7,161.1 1425.7,198.7 1340,198.7 1340,249 1418,249 1418,286.6 1340,286.6 1340,339.3 1427.2,339.3 1427.2,376.9 1297.7,376.9 " />
      <polygon points="1511.8,263.8 1494.9,229.9 1493.3,229.9 1498.3,263.8 1498.3,376 1458.2,376 1458.2,159.1 1489,159.1 1570.7,273.9 1587.1,306.8 1588.6,306.8 1583.7,273.9 1583.7,160.7 1623.8,160.7 1623.8,377.6 1592.9,377.6 " />
      <polygon points="1813.9,198.8 1751,198.8 1751,376.9 1708.7,376.9 1708.7,198.8 1645.5,198.7 1645.5,161.1 1813.9,161.2 " />
    </SVG>
  );
};
