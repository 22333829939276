import * as modalComponents from '../../modals';
import * as slices from '../../slices';
import { Clips, Footer, Intro, LocationToHash, SEO, Topbar } from './components';
import { CookiesConsent } from '../CookiesConsent/CookiesConsent';
import { GlobalStyle, PageWrapper } from '../../theme/components';
import { Helmet } from 'react-helmet';
import { ModalManager } from 'react-modal-handler';
import { PrismicReactToolsProvider } from 'prismic-react-tools';
import { Testimonials } from '../Testimonials/Testimonials';
import { ThemeProvider } from 'styled-components';
import { serializer } from '../../lib/serializer';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';

const isProduction = process.env.NODE_ENV === 'production';

const modals = {
  notice: modalComponents.NoticeModal
};

export const Layout = ({
  children,
  footer,
  locale,
  location,
  seo,
  testimonials,
  topbar,
  withScrollBehaviour,
  withTestimonials,
  withoutIntro
}) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalManager modals={modals} />
      <PrismicReactToolsProvider components={slices} serializer={serializer}>
        <Helmet>
          <link href="/img/favicon.ico" rel="icon" sizes="16x16" type="image/png" />
        </Helmet>
        <GlobalStyle />
        <SEO {...seo} />
        <LocationToHash {...location} />
        <React.Fragment>
          {!withoutIntro && isProduction && <Intro />}
          <Topbar {...topbar} locale={locale} withScrollBehaviour={withScrollBehaviour} />
          <PageWrapper>
            {children}
            {withTestimonials && <Testimonials {...testimonials} />}
            <Footer {...footer} withTestimonials={withTestimonials} />
            <CookiesConsent />
            <Clips />
          </PageWrapper>
        </React.Fragment>
      </PrismicReactToolsProvider>
      <div className="elfsight-app-47db337c-5c1b-46fc-a1c9-255e2d38623b" />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.object,
  locale: PropTypes.object,
  location: PropTypes.object,
  noticeModal: PropTypes.object,
  seo: PropTypes.object,
  testimonials: PropTypes.object,
  topbar: PropTypes.object,
  withScrollBehaviour: PropTypes.bool,
  withTestimonials: PropTypes.bool,
  withoutIntro: PropTypes.bool
};
