import { colors, fonts } from '../../variables';
import { generateProps } from 'styled-gen';
import styled from 'styled-components';

export const DocumentStyle = styled.div`
  font-size: ${fonts.size('md')};

  p,
  ul,
  ol {
    color: ${colors.n06};
    line-height: 1.75;

    b,
    strong {
      font-weight: ${fonts.weights.bold};
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4 {
      margin-top: 4rem;
    }

    & + p,
    & + ul,
    & + ol {
      margin-top: 1rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    & + p,
    & + ul,
    & + ol {
      margin-top: 1.5rem;
    }
  }

  ul {
    list-style: disc;
    padding-left: 2rem;

    li + li {
      margin-top: 1rem;
    }
  }

  a {
    color: inherit;
    font-weight: ${fonts.weights.semibold};
    text-decoration: underline;

    &:hover {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
  }

  ${generateProps};
`;
