/* eslint-disable sort-keys */
import { colors, fonts } from '../../variables';
import { generateProps, mq, variations } from 'styled-gen';
import { getTag } from '../../helpers';
import styled, { css } from 'styled-components';

const alignmentVariations = {
  default: css`
    text-align: left;
  `,

  centeredOnMobile: css`
    text-align: center;

    ${mq.tablet(css`
      text-align: left;
    `)}
  `,

  centeredOnTablet: css`
    text-align: center;

    ${mq.tabletLandscape(css`
      text-align: left;
    `)}
  `
};

const textVariations = {
  XLarge: css`
    font-size: 1.25rem;

    ${mq.tablet(css`
      font-size: 1.5rem;
    `)};
  `,

  large: css`
    font-size: 1.125rem;

    ${mq.tablet(css`
      font-size: 1.25rem;
    `)};
  `,

  default: css`
    font-size: 1rem;

    ${mq.tablet(css`
      font-size: 1.125rem;
    `)};
  `,

  mediumLarge: css`
    font-size: 1.075rem;

    ${mq.tablet(css`
      font-size: 1.375rem;
    `)};
  `,

  medium: css`
    font-size: 0.875rem;

    ${mq.tablet(css`
      font-size: 1rem;
    `)};
  `,

  small: css`
    font-size: 0.75rem;

    ${mq.tablet(css`
      font-size: 0.875rem;
    `)};
  `,

  XSmall: css`
    font-size: 0.65rem;

    ${mq.tablet(css`
      font-size: 0.75rem;
    `)};
  `,

  XXSmall: css`
    font-size: 0.625rem;
  `
};

const formatVariations = {
  default: css``,

  isLabel: css`
    font-weight: ${fonts.weights.bold};
    letter-spacing: 0.1em;
    text-transform: uppercase;
  `,

  withList: css`
    & > ul {
      list-style: disc;
    }
  `
};

export const Text = styled.p.attrs(props => ({
  as: getTag(props, { defaultTag: 'p' })
}))`
  color: ${colors.n06};
  font-weight: ${fonts.weights.regular};
  line-height: 1.75;
  text-align: left;

  ${variations(alignmentVariations)};
  ${variations(textVariations)};
  ${variations(formatVariations)};
  ${generateProps};
`;
