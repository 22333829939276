import { Text } from '../../../../theme/components/Typography';
import { colors, fonts } from '../../../../theme';
import { mq } from 'styled-gen';
import { size } from 'polished';
import styled, { css } from 'styled-components';

const FooterContent = styled.div`
  padding-top: 20rem;
  padding-bottom: 5.5rem;
  position: relative;
  z-index: 1;

  ${({ withTestimonials }) =>
    withTestimonials &&
    css`
      padding-top: 20rem;

      ${mq.tablet(css`
        padding-top: 32rem;
      `)}
    `}
`;

const FooterHeading = styled(Text).attrs({
  isLabel: true,
  medium: true
})`
  color: ${colors.n01};
  margin-bottom: 1.5rem;
`;

const FooterLabel = styled(Text).attrs({
  XXSmall: true,
  as: 'div',
  isLabel: true
})`
  color: ${colors.n01};
  opacity: 0.5;
  margin-bottom: 0.25rem;
`;

const FooterLangLink = styled(Text).attrs({
  XSmall: true,
  as: 'a',
  isLabel: true
})`
  color: ${colors.n01};

  & + & {
    margin-left: 0.5rem;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          cursor: default !important;
          opacity: 1;
        `
      : css`
          opacity: 0.5;

          ${mq.tabletLandscape(css`
            &:hover {
              opacity: 0.75;
            }
          `)}
        `}
`;

const FooterLegalMenuItem = styled.span`
  display: block;

  a {
    color: ${colors.n01};
    display: block;
    font-size: ${fonts.size('xxs')};
    text-decoration: underline;

    &:hover {
      opacity: 0.5;
      text-decoration: underline;
    }
  }

  & + & {
    margin-top: 1rem;
  }

  ${mq.tabletLandscape(css`
    display: inline-block;

    & + & {
      margin-left: 1rem;
      margin-top: 0;
    }
  `)}
`;

const FooterLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  color: inherit;
  font-weight: ${fonts.weights.semibold};
  text-decoration: underline;

  ${mq.tabletLandscape(css`
    &:hover {
      opacity: 0.5;
      text-decoration: underline;
    }
  `)}
`;

const FooterIconLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  ${size('1.5rem')};

  color: ${colors.n01};
  display: inline-block;

  svg {
    ${size('100%')};
  }

  & + & {
    margin-left: 1.5rem;
  }

  ${mq.tabletLandscape(css`
    &:hover {
      opacity: 0.5;
    }
  `)}
`;

const FooterText = styled(Text).attrs({
  as: 'div',
  small: true
})`
  color: ${colors.n01};
  line-height: 1.4;

  p + p {
    margin-top: 0.5rem;
  }
`;

const FooterWrapper = styled.div`
  position: relative;
  background-color: ${colors.n08};
  margin-top: auto;

  & > * {
    z-index: 1;
  }
`;

export {
  FooterContent,
  FooterHeading,
  FooterIconLink,
  FooterLabel,
  FooterLangLink,
  FooterLegalMenuItem,
  FooterLink,
  FooterText,
  FooterWrapper
};
