import { CurvedContainer } from '../../theme/components/CurvedContainer/CurvedContainer';
import { colors } from '../../theme/variables';
import { mq } from 'styled-gen';
import { position, size } from 'polished';
import styled, { css } from 'styled-components';

const HeroContentInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  h5 + * {
    margin-top: 0.5rem;
  }

  h2 + * {
    margin-top: 1.5rem;
  }
`;

const HeroContentWrapper = styled(CurvedContainer)`
  ${position('absolute', 0)};

  align-items: center;
  display: flex;
  justify-content: center;
`;

const HeroLogoWrapper = styled.div`
  max-width: 34rem;
  width: 100%;
`;

const HeroWrapper = styled.section`
  ${size('105vh', '100%')};

  background-color: ${colors.n08};
  overflow: hidden;
  position: relative;

  ${mq.tablet(css`
    ${size('42rem', '100%')};
  `)};
`;

export { HeroContentInner, HeroContentWrapper, HeroLogoWrapper, HeroWrapper };
