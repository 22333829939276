import { colors } from '../../theme';
import { ease, mq, transitions } from 'styled-gen';
import { position, rgba, size } from 'polished';
import styled, { css } from 'styled-components';

const VideoContainer = styled.div.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  background-color: ${colors.n01};
  border-radius: 0.375rem;
  box-shadow: 0 0 3.125rem ${rgba(colors.n08, 0.16)};
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
`;

const VideoContainerContent = styled.div`
  ${position('absolute', 0)};
  ${size('100%')};
`;

const VideoContainerCover = styled.div`
  ${size('100%')};

  align-items: center;
  background-image: url(${({ cover }) => cover});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
`;

const VideoContainerCoverButton = styled.a`
  ${size('8rem')};
  ${transitions(['background-color', 'color'], 200, ease.inOutSine)};

  align-items: center;
  background-color: ${rgba(colors.n01, 0.2)};
  border-radius: 50%;
  color: ${colors.n05};
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${rgba(colors.n01, 0.5)};
    color: ${colors.n08};
  }

  ${mq.tablet(css`
    ${size('12.5rem')};
  `)};
`;

const VideoContainerCoverIcon = styled.div`
  ${size('6rem')};

  align-items: center;
  background-color: ${rgba(colors.n01, 0.2)};
  border-radius: 50%;
  box-shadow: 0 0 3.125rem ${rgba(colors.n08, 0.16)};
  display: flex;
  justify-content: center;

  svg {
    ${size('1.75rem')};
  }

  ${mq.tablet(css`
    ${size('10rem')};

    svg {
      ${size('2.75rem')};
    }
  `)};
`;

const VideoPlayer = styled.div`
  ${size('100%')};

  position: relative;
`;

export {
  VideoContainer,
  VideoContainerContent,
  VideoContainerCover,
  VideoContainerCoverButton,
  VideoContainerCoverIcon,
  VideoPlayer
};
