import { PageContextConsumer } from '../PageProvider/PageProvider';
import { generateProps } from 'styled-gen';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  display: inline-block;

  ${generateProps};
`;

const getHref = (href, { locale, locales }) => {
  if (locales[locale].default) {
    return href;
  }

  return `/${locale}/${href}`.replace('//', '/');
};

export const LocalLink = props => {
  const { children, href, ...otherProps } = props;

  return (
    <PageContextConsumer>
      {data => {
        return (
          <Link {...otherProps} href={getHref(href, data)}>
            {children}
          </Link>
        );
      }}
    </PageContextConsumer>
  );
};

LocalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string
};
