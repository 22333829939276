import { Button, Div, DocumentStyle } from '../../theme/components';
import { Heading, Text } from '../../theme/components/Typography';
import { PlainText, RichText } from 'prismic-react-tools';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const SectionContent = props => {
  const {
    buttonLabel,
    buttonProps,
    buttonUrl,
    children,
    heading,
    headingProps,
    preHeading,
    preHeadingProps,
    text,
    textProps
  } = props;

  const withPreHeading = !!preHeading;
  const withHeading = !!heading;
  const withText = !isEmpty(text);

  return (
    <Div textAlign={{ md: 'left', xs: 'center' }}>
      {withPreHeading && (
        <Heading h5 p06 {...preHeadingProps}>
          <PlainText content={preHeading} />
        </Heading>
      )}
      {withHeading && (
        <Heading h2 mt={withPreHeading ? 1 : 0} withDot {...headingProps}>
          <PlainText content={heading} />
        </Heading>
      )}
      {withText && (
        <DocumentStyle>
          <Text as="div" centeredOnTablet mt={withPreHeading || withHeading ? 2 : 0} {...textProps}>
            <RichText content={text} />
          </Text>
        </DocumentStyle>
      )}
      {buttonLabel && buttonUrl && (
        <Button href={buttonUrl} mt={2} {...buttonProps}>
          {buttonLabel}
        </Button>
      )}
      {children && (
        <Div mt={2} textAlign={{ md: 'left', xs: 'center' }}>
          {children}
        </Div>
      )}
    </Div>
  );
};

SectionContent.propTypes = {
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  buttonUrl: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.string,
  headingProps: PropTypes.object,
  preHeading: PropTypes.string,
  preHeadingProps: PropTypes.object,
  text: PropTypes.any,
  textProps: PropTypes.object
};
