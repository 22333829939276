import { Col, CurvedContainer, Grid, Icon, Row } from '../../../../theme/components';
import {
  FooterContent,
  FooterHeading,
  FooterIconLink,
  FooterLabel,
  FooterLegalMenuItem,
  FooterLink,
  FooterText,
  FooterWrapper
} from './Footer.style';
import { LocalLink } from '../../../LocalLink/LocalLink';
import { RichText } from 'prismic-react-tools';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Footer = props => {
  const {
    addressHeading,
    contact,
    copyright,
    emailHeading,
    legalHeading,
    legalMenu,
    managementSystemHeading,
    managementSystemMenu,
    phoneHeading,
    socialHeading,
    withTestimonials
  } = props;
  const { address, distributorsEmail, distributorsEmailLabel, email, emailLabel, phones, social } = contact;

  return (
    <FooterWrapper>
      <CurvedContainer topCurve>
        <FooterContent withTestimonials={withTestimonials}>
          <Grid>
            <Row>
              <Col md={2} sm={3} xs={12}>
                <FooterHeading>{socialHeading}</FooterHeading>
                {social.map(({ icon, url }, index) => (
                  <FooterIconLink href={url} key={index}>
                    <Icon icon={icon} />
                  </FooterIconLink>
                ))}
              </Col>
              <Col md={4} mt={{ sm: 0, xs: 2 }} sm={3} xs={12}>
                <FooterHeading>{addressHeading}</FooterHeading>
                <FooterText>
                  <RichText content={address} />
                </FooterText>
              </Col>
              <Col mt={{ sm: 0, xs: 2 }} sm={3} xs={12}>
                <FooterHeading>{emailHeading}</FooterHeading>
                <FooterText>
                  <FooterLabel>{emailLabel}</FooterLabel>
                  <FooterLink href={`mailto:${email}`}>{email}</FooterLink>
                </FooterText>
                <FooterText mt={1.5}>
                  <FooterLabel>{distributorsEmailLabel}</FooterLabel>
                  <FooterLink href={`mailto:${distributorsEmail}`}>{distributorsEmail}</FooterLink>
                </FooterText>
              </Col>
              <Col mt={{ sm: 0, xs: 2 }} sm={3} xs={12}>
                <FooterHeading>{phoneHeading}</FooterHeading>
                {phones.map(({ label, phone }, index) => (
                  <FooterText key={index} mt={1.5}>
                    <FooterLabel>{label}</FooterLabel>
                    <FooterLink href={`tel:${phone}`}>{phone}</FooterLink>
                  </FooterText>
                ))}
              </Col>
            </Row>
            <Row bottom="xs" mt={3}>
              <Col md={2} sm={3} xs={12}>
                <FooterText XSmall>{copyright}</FooterText>
              </Col>
              {!isEmpty(legalMenu) && (
                <Col mt={{ sm: 0, xs: 2 }} sm={4} xs={12}>
                  <FooterLabel>{legalHeading}</FooterLabel>
                  {legalMenu.map(({ label, url }, index) => (
                    <FooterLegalMenuItem key={index}>
                      <LocalLink href={url}>{label}</LocalLink>
                    </FooterLegalMenuItem>
                  ))}
                </Col>
              )}
              {!isEmpty(managementSystemMenu) && (
                <Col mt={{ sm: 0, xs: 2 }} sm={4} xs={12}>
                  <FooterLabel>{managementSystemHeading}</FooterLabel>
                  {managementSystemMenu.map(({ label, url }, index) => (
                    <FooterLegalMenuItem key={index}>
                      <LocalLink href={url}>{label}</LocalLink>
                    </FooterLegalMenuItem>
                  ))}
                </Col>
              )}
            </Row>
          </Grid>
        </FooterContent>
      </CurvedContainer>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  addressHeading: PropTypes.string,
  contact: PropTypes.shape({
    address: PropTypes.any,
    addressLabel: PropTypes.string,
    distributorsEmail: PropTypes.string,
    distributorsEmailLabel: PropTypes.string,
    email: PropTypes.string,
    emailLabel: PropTypes.any,
    phoneLabel: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        phone: PropTypes.string
      })
    ),
    social: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        url: PropTypes.string
      })
    )
  }),
  copyright: PropTypes.string,
  emailHeading: PropTypes.string,
  legalHeading: PropTypes.string,
  legalMenu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    })
  ),
  locale: PropTypes.shape({
    default: PropTypes.bool,
    path: PropTypes.string
  }),
  managementSystemHeading: PropTypes.string,
  managementSystemMenu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    })
  ),
  phoneHeading: PropTypes.string,
  socialHeading: PropTypes.string,
  withTestimonials: PropTypes.bool
};
