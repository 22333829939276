import { AnimatedGroup } from '../../components';
import { Img, Section } from '../../theme/components';
import PropTypes from 'prop-types';
import React from 'react';

export const Image = props => {
  const {
    image: { url: image }
  } = props;

  return (
    <Section>
      <AnimatedGroup center="xs" row={false} stagger={0.1}>
        <Img src={image} />
      </AnimatedGroup>
    </Section>
  );
};

Image.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string
  })
};
