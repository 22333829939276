import { goToAnchor } from 'react-scrollable-anchor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class LocationToHash extends Component {
  static propTypes = {
    hash: PropTypes.string
  };

  componentDidMount() {
    const { hash } = this.props;

    if (hash) {
      setTimeout(() => {
        goToAnchor(hash.replace('#', ''));
      }, 100);
    }
  }

  render() {
    return <></>;
  }
}
