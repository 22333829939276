/* eslint-disable sort-keys */
import { colors, fonts } from '../../variables';
import { generateProps, mq, variations } from 'styled-gen';
import { getTag } from '../../helpers';
import styled, { css } from 'styled-components';

const alignmentVariations = {
  default: css`
    text-align: center;

    ${mq.tabletLandscape(css`
      text-align: left;
    `)}
  `,

  center: css`
    text-align: center;
  `,

  centeredOnMobile: css`
    text-align: center;

    ${mq.tablet(css`
      text-align: left;
    `)}
  `,

  hero: css`
    text-align: center;
  `
};

const headings = {
  default: css`
    font-size: 2rem;

    ${mq.tablet(css`
      font-size: 2.625rem;
    `)}
  `,

  h2: css`
    font-size: 1.75rem;

    ${mq.tablet(css`
      font-size: 2.375rem;
    `)}
  `,

  h3: css`
    font-size: 1.25rem;

    ${mq.tablet(css`
      font-size: 1.75rem;
    `)}
  `,

  h4: css`
    font-size: 1.2rem;
    font-weight: ${fonts.weights.regular};

    ${mq.tablet(css`
      font-size: 1.5rem;
    `)}
  `,

  h5: css`
    font-size: 1.15rem;
    font-weight: ${fonts.weights.bold};
    letter-spacing: 0.15em;
    text-transform: uppercase;

    ${mq.tablet(css`
      font-size: 1.25rem;
    `)}
  `,

  hero: css`
    font-size: 2.125rem;
    font-weight: ${fonts.weights.bold};
    line-height: 1.1;

    ${mq.tablet(css`
      font-size: 5rem;
    `)}
  `
};

export const Heading = styled.h1.attrs(props => ({
  as: getTag(props, { defaultTag: 'h1' })
}))`
  color: ${colors.n07};
  font-weight: ${fonts.weights.semibold};
  line-height: 1.25;

  ${({ withDot }) =>
    withDot &&
    css`
      &::after {
        content: '.';
        color: ${colors.p06};
      }
    `};

  ${variations(alignmentVariations)};
  ${variations(headings)};
  ${generateProps};
`;
