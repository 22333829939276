import { BaseModal } from './BaseModal';
import { Button, Div, DocumentStyle, Img } from '../theme/components';
import { Heading } from '../theme/components/Typography';
import { RichText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const NoticeModal = props => {
  const { controller, ...data } = props;

  const {
    buttonLabel,
    headerImage: { url: image },
    heading,
    onCloseCallback,
    text
  } = data;

  return (
    <BaseModal controller={controller}>
      <Div padding={2.5}>
        {image && <Img src={image} width="100%" />}
        {heading && (
          <Heading h3 left mt={image ? 2 : 0}>
            {heading}
          </Heading>
        )}
        <DocumentStyle as="div" mt={image || heading ? 2 : 0}>
          <RichText content={text} />
        </DocumentStyle>
        <Button mt={2} onClick={() => controller.onClose(onCloseCallback)}>
          {buttonLabel}
        </Button>
      </Div>
    </BaseModal>
  );
};

NoticeModal.propTypes = {
  controller: PropTypes.shape({
    onClose: PropTypes.any
  })
};
