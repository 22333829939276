import { colors } from '../../variables';
import { ease, generateProps, transitions } from 'styled-gen';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
  ${transitions('box-shadow', 250, ease.inOutCubic)};

  background-color: ${colors.n01};
  box-shadow: 0 0 3.125rem ${rgba(colors.n08, 0.16)};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2.5rem;
  position: relative;
  z-index: 10;

  ${generateProps};
`;
