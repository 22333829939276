import { AnimatedGroup, SectionHeading } from '../../components';
import { Col, CurvedContainer, Div, Grid, Icon, Section } from '../../theme/components';
import {
  VideoContainer,
  VideoContainerContent,
  VideoContainerCover,
  VideoContainerCoverButton,
  VideoContainerCoverIcon,
  VideoPlayer
} from './Video.style';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

export const Video = props => {
  const {
    cover: { url: cover },
    video,
    youtubeUrl,
    ...heading
  } = props;
  const videoUrl = get(video, 'url');

  const [showPlayer, setShowPlayer] = useState(!cover);

  if (!videoUrl && !youtubeUrl) {
    return null;
  }

  return (
    <>
      <CurvedContainer mt={{ sm: 20, xs: 10 }} topCurve topCurveInverted />
      <Section grayGradient>
        <Div>
          <SectionHeading {...heading} />
          <Grid>
            <AnimatedGroup center="xs">
              <Col sm={10} xs={12}>
                <VideoContainer>
                  <VideoContainerContent>
                    {showPlayer && (
                      <VideoPlayer>
                        <ReactPlayer
                          height="100%"
                          onEnded={() => cover && setShowPlayer(false)}
                          playing={cover}
                          url={videoUrl || youtubeUrl}
                          width="100%"
                        />
                      </VideoPlayer>
                    )}
                    {cover && (
                      <VideoContainerCover cover={cover}>
                        <VideoContainerCoverButton onClick={() => setShowPlayer(true)}>
                          <VideoContainerCoverIcon>
                            <Icon icon="play" />
                          </VideoContainerCoverIcon>
                        </VideoContainerCoverButton>
                      </VideoContainerCover>
                    )}
                  </VideoContainerContent>
                </VideoContainer>
              </Col>
            </AnimatedGroup>
          </Grid>
        </Div>
      </Section>
    </>
  );
};

Video.propTypes = {
  cover: PropTypes.shape({
    url: PropTypes.string
  }),
  heading: PropTypes.string,
  preHeading: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string
  }),
  youtubeUrl: PropTypes.string
};
