import { colors } from '../../theme';
import { ease, transitions } from 'styled-gen';
import { rgba } from 'polished';
import styled from 'styled-components';

const ArticleCardContent = styled.div`
  ${transitions('box-shadow', 250, ease.inOutCubic)};

  background-color: ${colors.n01};
  box-shadow: 0 0 3.125rem ${rgba(colors.n08, 0.16)};
  display: block;
  padding: 2.5rem;
  position: relative;
  z-index: 10;

  &:hover {
    box-shadow: 0 0 4rem ${rgba(colors.n08, 0.32)};
  }
`;

const ArticleCardWrapper = styled.div`
  & + & {
    margin-top: 3.5rem;
  }
`;

export { ArticleCardContent, ArticleCardWrapper };
