import { AnimatedEl, AnimatedGroup } from '../Animations';
import { Button, Div, DocumentStyle, Grid } from '../../theme/components';
import { Heading, Text } from '../../theme/components/Typography';
import { PlainText, RichText } from 'prismic-react-tools';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const SectionHeading = props => {
  const {
    buttonLabel,
    buttonProps,
    buttonUrl,
    children,
    colWidth,
    heading,
    headingProps,
    preHeading,
    preHeadingProps,
    text,
    textProps,
    white,
    ...other
  } = props;

  const withHeading = !!heading;
  const withPreHeading = !!preHeading;
  const withText = !isEmpty(get(text, '0.text') || !isEmpty(get(text, '0.spans')));

  return (
    <Grid>
      <AnimatedGroup center="xs">
        <AnimatedEl md={colWidth || 6} xs={12}>
          {(withPreHeading || withHeading || withText) && (
            <Div mb={{ md: 4.5, xs: 2.25 }} {...other}>
              {withPreHeading && (
                <Heading alpha={white ? 0.5 : 1} center h5 n01={white} p06={!white} {...preHeadingProps}>
                  <PlainText content={preHeading} />
                </Heading>
              )}
              {withHeading && (
                <Heading center h2 mt={{ xs: withPreHeading ? 1.5 : 0 }} n01={white} n07={!white} {...headingProps}>
                  <PlainText content={heading} />
                </Heading>
              )}
              {withText && (
                <DocumentStyle>
                  <Text
                    as="div"
                    center
                    mt={{ xs: withPreHeading || withHeading ? 2 : 0 }}
                    n01={white}
                    n06={!white}
                    {...textProps}
                  >
                    <RichText content={text} />
                  </Text>
                </DocumentStyle>
              )}
              {buttonLabel && buttonUrl && (
                <Button href={buttonUrl} mt={2} {...buttonProps}>
                  {buttonLabel}
                </Button>
              )}
            </Div>
          )}
          {children}
        </AnimatedEl>
      </AnimatedGroup>
    </Grid>
  );
};

SectionHeading.propTypes = {
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  buttonUrl: PropTypes.string,
  children: PropTypes.node,
  colWidth: PropTypes.number,
  heading: PropTypes.string,
  headingProps: PropTypes.object,
  preHeading: PropTypes.string,
  preHeadingProps: PropTypes.object,
  text: PropTypes.any,
  textProps: PropTypes.object,
  white: PropTypes.bool
};
