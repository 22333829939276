import { Heading } from '../theme/components/Typography';
import { bracked } from 'prismic-react-tools';
import React from 'react';
import parse from 'html-react-parser';

/* eslint-disable-next-line max-params */
export const serializer = (variables, type, element, children, content, key) => {
  const { data, text } = element;

  if (type.startsWith('heading')) {
    const headingType = `${type.replace('heading', 'h')}`;
    const props = {
      [headingType]: true,
      left: true
    };

    return (
      <Heading {...props} key={key}>
        {content}
      </Heading>
    );
  }

  if (type !== 'span' && type !== 'hyperlink' && type !== 'image') {
    return null;
  }

  if (type === 'span') {
    return parse(bracked(text, variables));
  }

  if (type === 'image') {
    const { copyright: href, url: src } = element;

    if (href) {
      return (
        <a className="hst-rich-text-image-link" href={href} key={key} rel="noreferrer noopener" target="_blank">
          <img className="hst-rich-text-image" key={key} src={src} />
        </a>
      );
    }

    return <img className="hst-rich-text-image" src={src} />;
  }

  const { url } = data;

  return (
    <a href={url} key={key} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};
