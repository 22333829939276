import { colors } from '../../variables';
import { generateProps, mq } from 'styled-gen';
import { size } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${size('100%')};

  position: relative;

  ${generateProps};
`;

const Curve = styled.svg.attrs({
  preserveAspectRatio: 'xMidYMin slice',
  viewBox: '0 0 1440 84',
  xmlns: 'http://www.w3.org/2000/svg'
})`
  position: absolute;
  width: 150%;

  ${mq.desktop(css`
    height: auto;
    width: 100%;
  `)};

  ${({ bottom }) =>
    bottom &&
    css`
      bottom: 0;
    `};

  ${({ top }) =>
    top &&
    css`
      top: 0;
    `};

  ${({ inverted }) =>
    inverted
      ? css`
          left: 50%;
          transform: scaleX(-1) translateX(50%);
        `
      : css`
          left: 50%;
          transform: translateX(-50%);
        `};
`;

export const CurvedContainer = props => {
  const { bgColor, bottomCurve, bottomCurveInverted, children, topCurve, topCurveInverted, ...other } = props;

  return (
    <Wrapper {...other}>
      {topCurve && (
        <Curve fill={colors[bgColor] || colors.n01} inverted={topCurveInverted} top>
          <path d="M1440 58C1277.2 11.1 1103.3.3 932.5 14.4S444 84 259.6 84 0 58 0 58V0h1440v58z" />
        </Curve>
      )}
      {children}
      {bottomCurve && (
        <Curve bottom fill={colors[bgColor] || colors.n01} inverted={bottomCurveInverted}>
          <path d="M1440 50.2C1277.2 2.6 1103.3-8.5 932.5 5.9S444 76.7 259.6 76.7 0 50.2 0 50.2V84h1440V50.2z" />
        </Curve>
      )}
    </Wrapper>
  );
};

CurvedContainer.propTypes = {
  bgColor: PropTypes.string,
  bottomCurve: PropTypes.bool,
  bottomCurveInverted: PropTypes.bool,
  children: PropTypes.node,
  topCurve: PropTypes.bool,
  topCurveInverted: PropTypes.bool
};
