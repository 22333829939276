import { colors } from '../../theme';
import { linearGradient, size } from 'polished';
import { mq } from 'styled-gen';
import { splats } from './Splats';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  ${({ withSplat }) =>
    withSplat &&
    css`
      height: 12rem;

      ${mq.tablet(css`
        height: 25rem;

        ${({ side }) => css`
          justify-content: ${side !== 'right' ? 'flex-end' : 'flex-start'};
        `}
      `)}

      ${mq.tabletLandscape(css`
        height: 35rem;
      `)}
    `}
`;

const ImgWrapper = styled.div`
  ${({ image, splat }) =>
    splat
      ? css`
    ${size('12rem', `${12 / 0.75}rem`)};

    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    z-index: 1;

    ${mq.tablet(css`
      ${size('25rem', `${25 / 0.75}rem`)};
    `)}

    ${mq.tabletLandscape(css`
      ${size('35rem', `${35 / 0.75}rem`)};
    `)}

    ${({ splat }) => css`
      clip-path: url(#${splat});
    `};
  `
      : css`
          background-image: url(${image});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          /* border-radius: 50%; */
          overflow: hidden;
          padding-top: 100%;
          width: 100%;
        `}
`;

const Img = styled.img`
  height: 100%;
  width: auto;
`;

const Overlay = styled.div`
  ${size('100%')};

  ${linearGradient({
    colorStops: [`${colors.p04} 0%`, `${colors.i06} 100%`],
    fallback: colors.p06,
    toDirection: 'to bottom'
  })}

  opacity: 0.6;
  position: absolute;
`;

export const SectionImage = props => {
  const { side, splat, src } = props;
  const Splat = splats[splat] ? splats[splat] : null;

  return (
    <Wrapper side={side} withSplat={!!Splat}>
      <ImgWrapper image={src} splat={splat}>
        {!!Splat && (
          <>
            <Img src={src} />
            <Overlay />
          </>
        )}
      </ImgWrapper>
      {!!Splat && <Splat />}
    </Wrapper>
  );
};

SectionImage.propTypes = {
  bigSplat: PropTypes.bool,
  side: PropTypes.string,
  splat: PropTypes.string,
  src: PropTypes.string
};
