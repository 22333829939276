import { AnimatedEl, AnimatedGroup } from '../Animations';
import { Cards } from '../../lib/Cards';
import { Grid, Icon, Section } from '../../theme/components';
import { RichText } from 'prismic-react-tools';
import { SectionHeading } from '../SectionHeading/SectionHeading';
import {
  TestimonialsArrow,
  TestimonialsCard,
  TestimonialsCardContent,
  TestimonialsName,
  TestimonialsPosition,
  TestimonialsText,
  TestimonialsWrapper
} from './Testimonials.style';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class Testimonials extends Component {
  static propTypes = {
    heading: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        person: PropTypes.string,
        position: PropTypes.string,
        text: PropTypes.any
      })
    ),
    preHeading: PropTypes.string,
    withNoNegativeMargin: PropTypes.bool
  };

  state = {
    cards: null
  };

  componentDidMount() {
    const { items } = this.props;
    const cards = [...items];

    this.setState({ cards }, () =>
      setTimeout(() => {
        this.CardsController = new Cards();
      })
    );
  }

  render() {
    const { cards } = this.state;
    const { heading, preHeading, withNoNegativeMargin } = this.props;

    return (
      <Section mb={{ md: 10, sm: 5, xs: 2.5 }} relative zIndex={10}>
        <SectionHeading heading={heading} preHeading={preHeading} />
        <Grid>
          <AnimatedGroup>
            <AnimatedEl xs={12}>
              {cards && (
                <TestimonialsWrapper data-testimonial-cards withNoNegativeMargin={withNoNegativeMargin}>
                  <TestimonialsArrow data-testimonial-arrow="left" side="left">
                    <Icon icon="left" />
                  </TestimonialsArrow>
                  {cards.map(({ person, position, text }, index) => (
                    <TestimonialsCard data-testimonial-card index={index} key={index} top={index * 1} zIndex={-index}>
                      <TestimonialsCardContent>
                        <TestimonialsText>
                          <RichText content={text} />
                        </TestimonialsText>
                        <TestimonialsName>{person}</TestimonialsName>
                        <TestimonialsPosition>{position}</TestimonialsPosition>
                      </TestimonialsCardContent>
                    </TestimonialsCard>
                  ))}
                  <TestimonialsArrow data-testimonial-arrow="right" side="right">
                    <Icon icon="right" />
                  </TestimonialsArrow>
                </TestimonialsWrapper>
              )}
            </AnimatedEl>
          </AnimatedGroup>
        </Grid>
      </Section>
    );
  }
}
