import { AnimatedEl, AnimatedGroup, SectionContent, SectionHeading } from '../../components';
import { Card, Grid, Icon, Section } from '../../theme/components';
import { colors, fonts } from '../../theme';
import { ease, transitions } from 'styled-gen';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Image = styled.div`
  background-image: url("${({ src }) => src}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 12rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const TextLink = styled.a`
  ${transitions('all', '0.15s', ease.inOutCubic)};

  color: ${colors.p06};
  display: inline-flex;
  font-weight: ${fonts.weights.semibold};
  margin-top: auto;
  margin-right: auto;
  padding-top: 1rem;

  &:hover {
    color: ${colors.n06};
  }
`;

export const InfoCards = props => {
  const { items, ...heading } = props;

  return (
    <Section smallPadding>
      <SectionHeading {...heading} />
      <Grid mb={-3}>
        <AnimatedGroup center="xs">
          {items.map(({ buttonLabel, buttonUrl, image: { url: image }, ...content }, index) => (
            <AnimatedEl key={index} mb={3} md={4} sm={6} xs={12}>
              <Card>
                <Image src={image} />
                <SectionContent
                  {...content}
                  headingProps={{ h4: true, semibold: true }}
                  textProps={{ overflow: 'hidden', small: true }}
                />
                <TextLink href={buttonUrl}>
                  {buttonLabel}
                  <Icon icon="arrowRight" ml={0.25} size={1.5} />
                </TextLink>
              </Card>
            </AnimatedEl>
          ))}
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

InfoCards.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonUrl: PropTypes.string,
      heading: PropTypes.string,
      text: PropTypes.any
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
