import { colors } from '../..';
import { generateProps, mq, variations } from 'styled-gen';
import { linearGradient } from 'polished';
import styled, { css } from 'styled-components';

const spacingVariants = {
  default: css`
    padding-top: 7.5rem;

    ${mq.tablet(css`
      padding-top: 15rem;
    `)}
  `,

  mediumPadding: css`
    padding-top: 4rem;

    ${mq.tablet(css`
      padding-top: 8rem;
    `)}
  `,

  noPadding: css`
    padding: 0;
  `,

  smallPadding: css`
    padding-top: 3.75rem;

    ${mq.tablet(css`
      padding-top: 7.5rem;
    `)}
  `
};

const gradientVariant = {
  default: css``,

  grayGradient: css`
    ${linearGradient({
      colorStops: [`${colors.n01} 0%`, `${colors.n02} 100%`],
      fallback: colors.n01,
      toDirection: 'to top'
    })}
  `
};

export const Section = styled.section`
  ${variations(spacingVariants)};
  ${variations(gradientVariant)};
  ${generateProps};
`;
