import { AnimatedGroup, SectionHeading } from '../../components';
import { Col, Grid, Img, Section } from '../../theme/components';
import { RichText } from 'prismic-react-tools';
import { Text } from '../../theme/components/Typography';
import PropTypes from 'prop-types';
import React from 'react';

export const IconList = props => {
  const { items, ...heading } = props;

  return (
    <Section>
      <SectionHeading {...heading} />
      <Grid>
        <AnimatedGroup center="xs" stagger={0.1}>
          {items.map(({ heading, icon: { url: icon }, text }, index) => (
            <Col
              key={index}
              left
              md={3}
              mdOffset={index % 3 === 0 ? 1 : 0}
              mt={{ md: index > 2 ? 3.5 : 0, xs: 2 }}
              sm={4}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <Img size={5.25} src={icon} />
              <Text centeredOnMobile mt={1} n08 semibold>
                {heading}
              </Text>
              <Text as="div" centeredOnMobile medium mt={1} paragraph>
                <RichText content={text} />
              </Text>
            </Col>
          ))}
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

IconList.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string
      }),
      text: PropTypes.any
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
