import { AnimatedEl, AnimatedGroup, SectionContent, SectionHeading, SectionImage } from '../../components';
import { Grid, Section } from '../../theme/components';
import PropTypes from 'prop-types';
import React from 'react';

export const TextColumns = props => {
  const { items, ...heading } = props;

  return (
    <Section smallPadding>
      <SectionHeading {...heading} />
      <Grid>
        {items.map(({ image: { url: image }, ...content }, index) => (
          <AnimatedGroup
            center="xs"
            key={index}
            middle="xs"
            mt={{ md: index !== 0 ? 12 : 0, xs: index !== 0 ? 6 : 0 }}
            reverse={index % 2 !== 0}
          >
            <AnimatedEl
              mb={{ md: 0, xs: 4 }}
              md={6}
              mdOffset={index % 2 !== 0 ? 1 : null}
              sm={8}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <SectionImage side={index % 2 !== 0 ? 'right' : 'left'} src={image} />
            </AnimatedEl>
            <AnimatedEl
              animation={{ from: index % 2 === 0 ? 'right' : 'left' }}
              md={5}
              mdOffset={index % 2 === 0 ? 1 : null}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <SectionContent {...content} buttonProps={{ large: true }} />
            </AnimatedEl>
          </AnimatedGroup>
        ))}
      </Grid>
    </Section>
  );
};

TextColumns.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonUrl: PropTypes.string,
      heading: PropTypes.string,
      text: PropTypes.any
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};
