import React from 'react';

export const Clips = () => (
  <>
    <svg height="0" viewBox="0 0 800 600" width="0">
      <defs>
        <clipPath clipPathUnits="objectBoundingBox" id="mainSplat" transform={`scale(${1 / 800} ${1 / 600})`}>
          <path d="M44.569 506.563s-111.722-118.395 18.6-209.585 168.5-87.62 201.848-175.849 140.116-140.5 300.24-114.4 261.954 111.9 228.321 282.144-306.121 274.723-352.55 288.266-146.981 33.286-242.187 16.267-154.272-86.843-154.272-86.843z" />
        </clipPath>
      </defs>
    </svg>
    <svg height="0" viewBox="0 0 750 562.5" width="0">
      <defs>
        <clipPath clipPathUnits="objectBoundingBox" id="splat1" transform={`scale(${1 / 750} ${1 / 562})`}>
          <path d="M.349 220.161c5.175-79.06 37.2-188.157 214.5-208.564S443.39.656 522.38 17.372 825 154.198 724.492 388.347s-294.6 199.707-486.329 85.872S-4.827 299.22.349 220.161z" />
        </clipPath>
      </defs>
    </svg>
    <svg height="0" viewBox="0 0 770.563 571.612" width="0">
      <defs>
        <clipPath clipPathUnits="objectBoundingBox" id="splat2" transform={`scale(${1 / 770} ${1 / 571.612})`}>
          <path d="M.012 214.198c-.719-29.937 31.229-165.781 183.4-176.914S318.678 20.622 431.269 3.443s203.373 30.768 282.628 125.422 79.494 190.57-25.484 304.253-167.812 169.836-241.9 120.552-226.417-182.034-313.875-197.344S.727 244.135.012 214.198z" />
        </clipPath>
      </defs>
    </svg>
    <svg height="0" viewBox="0 0 800 600" width="0">
      <defs>
        <clipPath clipPathUnits="objectBoundingBox" id="splat3" transform={`scale(${1 / 800} ${1 / 600})`}>
          <path d="M44.569 506.563s-111.722-118.395 18.6-209.585 168.5-87.62 201.848-175.849 140.116-140.5 300.24-114.4 261.954 111.9 228.321 282.144-306.121 274.723-352.55 288.266-146.981 33.286-242.187 16.267-154.272-86.843-154.272-86.843z" />
        </clipPath>
      </defs>
    </svg>
  </>
);
