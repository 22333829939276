import { Button, Icon, Logo } from '../../../../theme/components';
import { LogoCut } from './LogoCut';
import {
  TopbarContent,
  TopbarIconButton,
  TopbarLanguageButton,
  TopbarLanguageList,
  TopbarLanguageListItem,
  TopbarLanguageListItemLink,
  TopbarLanguageWrapper,
  TopbarMenu,
  TopbarMenuDesktopContainer,
  TopbarMenuItem,
  TopbarMenuItemLabel,
  TopbarMenuItemSubmenu,
  TopbarMenuItemSubmenuItem,
  TopbarMenuItemSubmenuItemLink,
  TopbarMenuItemSubmenuItemLinkHeading,
  TopbarMenuItemSubmenuItemLinkLabel,
  TopbarMenuItemSubmenuItemLinkText,
  TopbarMobileMenu,
  TopbarMobileMenuWrapper,
  TopbarWrapper
} from './Topbar.style';
import { filter, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class Topbar extends Component {
  static propTypes = {
    buttonLabel: PropTypes.string,
    contact: PropTypes.shape({
      distributorsEmail: PropTypes.string,
      distributorsEmailLabel: PropTypes.string,
      email: PropTypes.string,
      emailLabel: PropTypes.string,
      phone: PropTypes.string,
      phoneLabel: PropTypes.string
    }),
    langList: PropTypes.object,
    locale: PropTypes.shape({
      default: PropTypes.bool,
      path: PropTypes.string
    }),
    location: PropTypes.shape({
      href: PropTypes.string,
      origin: PropTypes.string,
      pathname: PropTypes.string
    }),
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string
          })
        ),
        primary: PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
          visibility: PropTypes.string
        })
      })
    ),
    uri: PropTypes.string,
    withScrollBehaviour: PropTypes.bool
  };

  state = {
    isMenuOpen: false,
    isScrolling: false
  };

  topbarRef = React.createRef();

  componentDidMount() {
    const { withScrollBehaviour } = this.props;

    if (withScrollBehaviour) {
      window.addEventListener('scroll', this.handleScroll, true);
    } else {
      this.setState({ isScrolling: true });
    }
  }

  componentWillUnmount() {
    const { withScrollBehaviour } = this.props;

    if (withScrollBehaviour) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  getContacts = () => {
    const { contact } = this.props;

    return ['email', 'distributorsEmail', 'phone'].reduce(
      (prev, current) => [
        ...prev,
        {
          label: contact[`${current}Label`],
          value: contact[current]
        }
      ],
      []
    );
  };

  getLangs = () => {
    const { langList } = this.props;

    return [
      filter(langList, 'default')[0],
      ...Object.keys(langList).reduce(
        (acc, prev) => (langList[prev].default ? acc : [...acc, { ...langList[prev], name: prev }]),
        []
      )
    ];
  };

  handleLanguageSelect = lang => {
    const { default: isCurrentDefault, path: currentPath } = this.props.locale;
    const { default: isNextDefault, path: nextPath } = lang;
    const { href, origin, pathname } = this.props.location;

    let path;

    if (isNextDefault) {
      path = href.replace(`/${currentPath}/`, '/');
    }

    if (isCurrentDefault) {
      path = `${origin}/${nextPath}${pathname}`;
    }

    if (!isCurrentDefault && !isNextDefault) {
      path = href.replace(`/${currentPath}/`, `/${nextPath}/`);
    }

    window.location.href = path;
  };

  handleMenuButtonClick = () => {
    this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
  };

  handleScroll = () => {
    const isScrolling = window.scrollY > 100;

    if (this.state.isScrolling !== isScrolling) {
      return this.setState({ isScrolling });
    }
  };

  render() {
    const { isScrolling, isMenuOpen } = this.state;
    const { locale } = this.props;

    const homeUrl = locale.default ? '/' : `/${locale.path}`;

    return (
      <>
        <TopbarWrapper isScrolling={isScrolling}>
          <TopbarContent isScrolling={isScrolling || isMenuOpen}>
            <a href={homeUrl} style={{ lineHeight: 1 }}>
              <Logo large={!isScrolling} white={!isScrolling && !isMenuOpen} />
            </a>
            <TopbarMenuDesktopContainer>
              <TopbarMenu>
                {this.renderMenuItems()}
                {this.renderContactButton()}
                {this.renderLangsButton()}
              </TopbarMenu>
            </TopbarMenuDesktopContainer>
            <TopbarIconButton onClick={this.handleMenuButtonClick} white={!isScrolling && !isMenuOpen}>
              <Icon icon={isMenuOpen ? 'times' : 'menu'} />
            </TopbarIconButton>
          </TopbarContent>
          {this.renderMobileMenu()}
        </TopbarWrapper>
      </>
    );
  }

  renderContactButton = () => {
    const { isScrolling } = this.state;
    const { buttonLabel } = this.props;

    return (
      <TopbarMenuItem>
        <Button className="topbar-button" lined={isScrolling} small white={!isScrolling}>
          {buttonLabel}
        </Button>
        <TopbarMenuItemSubmenu className="hst-submenu">
          {this.getContacts().map(({ label, value }) => (
            <TopbarMenuItemSubmenuItem key={value}>
              <TopbarMenuItemSubmenuItemLink
                href={`${value.startsWith('+') ? 'tel:' : 'mailto:'}${value}`}
                rel="noopener noreferrer no"
                target="_blank"
              >
                <TopbarMenuItemSubmenuItemLinkHeading>{label}</TopbarMenuItemSubmenuItemLinkHeading>
                <TopbarMenuItemSubmenuItemLinkText>{value}</TopbarMenuItemSubmenuItemLinkText>
              </TopbarMenuItemSubmenuItemLink>
            </TopbarMenuItemSubmenuItem>
          ))}
        </TopbarMenuItemSubmenu>
      </TopbarMenuItem>
    );
  };

  renderLangsButton = () => {
    const { isScrolling } = this.state;
    const langs = this.getLangs();

    if (langs.length < 2) {
      return;
    }

    return (
      <TopbarLanguageWrapper>
        <TopbarLanguageButton alternative={!isScrolling}>
          {get(filter(langs, 'isActive'), '0.path')}
          <Icon icon="caretDown" />
        </TopbarLanguageButton>
        {this.renderLangsMenu()}
      </TopbarLanguageWrapper>
    );
  };

  renderLangsMenu = () => {
    const langs = this.getLangs();

    if (langs.length < 2) {
      return;
    }

    return (
      <TopbarLanguageList className="hst-language-list">
        {langs.map(lang => {
          const { isActive, path } = lang;

          return (
            <TopbarLanguageListItem key={path}>
              <TopbarLanguageListItemLink
                isActive={isActive}
                onClick={() => !isActive && this.handleLanguageSelect(lang)}
              >
                {path}
              </TopbarLanguageListItemLink>
            </TopbarLanguageListItem>
          );
        })}
      </TopbarLanguageList>
    );
  };

  renderMenuItems = () => {
    const { isScrolling } = this.state;
    const { menu, uri } = this.props;

    return menu.map(({ primary: { label, url, visibility }, items }, index) => (
      <TopbarMenuItem
        isActive={uri !== '/' && uri.startsWith(url)}
        isScrolling={isScrolling}
        key={index}
        visibility={visibility !== 'everywhere' ? 'hidden' : 'visible'}
      >
        <TopbarMenuItemLabel href={url} isScrolling={isScrolling}>
          {label}
          {!!items.length && <Icon icon="caretDown" ml={0.5} />}
        </TopbarMenuItemLabel>
        {!!items.length && (
          <TopbarMenuItemSubmenu className="hst-submenu">
            {items.map(({ label, url }, index) => (
              <TopbarMenuItemSubmenuItem key={index}>
                <TopbarMenuItemSubmenuItemLink href={url}>
                  <TopbarMenuItemSubmenuItemLinkLabel>
                    <span>{label}</span>
                  </TopbarMenuItemSubmenuItemLinkLabel>
                </TopbarMenuItemSubmenuItemLink>
              </TopbarMenuItemSubmenuItem>
            ))}
          </TopbarMenuItemSubmenu>
        )}
      </TopbarMenuItem>
    ));
  };

  renderMobileMenu = () => {
    const { isMenuOpen } = this.state;

    return (
      <TopbarMobileMenuWrapper isActive={isMenuOpen}>
        <TopbarMobileMenu>
          {this.renderMenuItems()}
          {this.renderContactButton()}
          {this.renderLangsMenu()}
          <LogoCut />
        </TopbarMobileMenu>
      </TopbarMobileMenuWrapper>
    );
  };
}
