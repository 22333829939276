export const colors = {
  e06: '#d0021b',

  i06: '#0e5be8',

  n01: '#ffffff',
  n02: '#f3f8fa',
  n03: '#eff5fe',
  n04: '#a0b3cf',
  n05: '#a6b2c2',
  n06: '#637897',
  n07: '#07275a',
  n08: '#404852',
  n035: '#d7e5ea',

  p04: '#0bd9f7',
  p05: '#20acd6',
  p06: '#16d6c7',

  w06: '#ffb93a'
};
