import { Animated } from './Animated';
import { Col, Div } from '../../theme/components';
import PropTypes from 'prop-types';
import React from 'react';

export const AnimatedEl = ({ animation, children, col, event, parent, stagger, tween, ...props }) => {
  const Wrapper = col ? Col : Div;
  const Parent = parent || Wrapper;

  return (
    <Animated animation={animation} event={event} stagger={stagger} tween={tween}>
      <Parent {...props} data-animated="animated-item">
        {children}
      </Parent>
    </Animated>
  );
};

AnimatedEl.propTypes = {
  animation: PropTypes.object,
  children: PropTypes.node,
  col: PropTypes.bool,
  event: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  parent: PropTypes.any,
  stagger: PropTypes.number,
  tween: PropTypes.object
};

AnimatedEl.defaultProps = {
  col: true
};
