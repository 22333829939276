import { ArticleCardContent, ArticleCardWrapper } from './ArticleCard.style';
import { Heading, Text } from '../../theme/components/Typography';
import { Img } from '../../theme/components';
import { LocalLink } from '../LocalLink/LocalLink';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const ArticleCard = props => {
  const { date, description, image, title, uid } = props;

  const articleCardDate = new Date(date);

  return (
    <ArticleCardWrapper>
      <LocalLink href={`/news/${uid}`}>
        <ArticleCardContent>
          {!isEmpty(image) && <Img mb={0.5} src={get(image, 'url')} />}
          <Text isLabel n04 XSmall>
            {articleCardDate.toDateString()}
          </Text>
          {title && (
            <Heading h3 left mt={0.5}>
              {title}
            </Heading>
          )}
          {description && (
            <Text lg mt={0.5}>
              {description}
            </Text>
          )}
        </ArticleCardContent>
      </LocalLink>
    </ArticleCardWrapper>
  );
};

ArticleCard.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string
  }),
  keywords: PropTypes.string,
  title: PropTypes.string,
  uid: PropTypes.string
};
