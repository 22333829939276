import { colors } from '../theme';
import { ease, transitions } from 'styled-gen';
import { position, rgba } from 'polished';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

const modalOpenAnimationTime = 500;

const ModalBackdrop = styled.div`
  ${position('absolute', 0)};
  ${transitions(['visibility', 'opacity'], modalOpenAnimationTime / 2, 'linear')};

  background-color: ${rgba(colors.n01, 0.95)};
  opacity: 0;
  visibility: hidden;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

const ModalContainer = styled.div`
  ${transitions('all', modalOpenAnimationTime, ease.inOutExpo)};

  background-color: ${colors.n01};
  border-radius: 0.5rem;
  box-shadow: 0 0 3rem ${rgba(colors.n07, 0.16)};
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  overflow: auto;
  position: relative;
  transform: translateY(10rem);
  width: 30rem;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};
`;

const ModalWrapper = styled.div`
  ${position('fixed', 0)};

  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 99999;
`;

export const BaseModal = props => {
  const { children, controller } = props;
  const { isActive } = controller;
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    if (!isActive && isVisible) {
      setIsOpen(false);
      setTimeout(() => !isCancelled && setIsVisible(false), modalOpenAnimationTime);
    }
    if (isActive) {
      setIsVisible(true);
      setTimeout(() => setIsOpen(true), 100);
    }

    return () => {
      isCancelled = true;
    };
  }, [isActive]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalWrapper>
      <ModalBackdrop isActive={isOpen} />
      <ModalContainer isActive={isOpen}>{children}</ModalContainer>
    </ModalWrapper>,
    document.body
  );
};

BaseModal.propTypes = {
  children: PropTypes.any,
  controller: PropTypes.shape({
    isActive: PropTypes.bool,
    onClose: PropTypes.func
  })
};
