import { colors } from '../../../../theme';
import { position, size } from 'polished';
import { transitions } from 'styled-gen';
import styled, { css } from 'styled-components';

const IntroAnimation = styled.div`
  ${size('18.75rem')};
`;

const IntroWrapper = styled.div`
  ${position('fixed', 0)};
  ${transitions('all', '0.25s', 'linear')};

  align-items: center;
  background-color: ${colors.n01};
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

export { IntroAnimation, IntroWrapper };
