import { generateProps } from 'styled-gen';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  ${generateProps};

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
`;
