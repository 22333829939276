import { Button, Col, Grid, Logo, Row } from '../../theme/components';
import { Heading, Text } from '../../theme/components/Typography';
import { HeroContentInner, HeroContentWrapper, HeroLogoWrapper, HeroWrapper } from './Hero.style';
import { RichText } from 'prismic-react-tools';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Hero = props => {
  const { buttonLabel, buttonUrl, children, colWidth, heading, preHeading, text, withLogo } = props;

  return (
    <HeroWrapper>
      <HeroContentWrapper bottomCurve>
        <Grid zIndex={1}>
          <Row center="xs" middle="xs">
            <Col md={colWidth} xs={12}>
              <HeroContentInner>
                {withLogo && (
                  <HeroLogoWrapper>
                    <Logo fluid white />
                  </HeroLogoWrapper>
                )}
                {!!preHeading && (
                  <Heading alpha={0.5} fillColor="n01" h5>
                    {preHeading}
                  </Heading>
                )}
                {!isEmpty(heading) && (
                  <Heading center fillColor="n01" h2>
                    {heading}
                  </Heading>
                )}
                {!isEmpty(text) && (
                  <Text as="div" fillColor="n01" large textAlign="center">
                    <RichText content={text} />
                  </Text>
                )}
                {!!buttonLabel && !!buttonUrl && (
                  <Button href={buttonUrl} large mt={2}>
                    {buttonLabel}
                  </Button>
                )}
                {children}
              </HeroContentInner>
            </Col>
          </Row>
        </Grid>
      </HeroContentWrapper>
    </HeroWrapper>
  );
};

Hero.propTypes = {
  backgroundImage: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  children: PropTypes.node,
  colWidth: PropTypes.number,
  heading: PropTypes.string,
  preHeading: PropTypes.string,
  text: PropTypes.any,
  withLogo: PropTypes.bool
};

Hero.defaultProps = {
  colWidth: 6
};
